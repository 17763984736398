import React from "react"
import Header from "../components/header"

export default function NotFound() {
  return (
    <div>
      <Header page="resources" />
      <div>
        <h1>Page not Found</h1>
      </div>
    </div>
  )
}
