import { Link } from "gatsby"
import React from "react"
import Wordmark from "../../static/images/wordmark.svg"
import Menu from "../../static/images/menu.svg"

const links = [
  { text: "Groups", url: "groups" },
  { text: "Resources", url: "resources" },
  { text: "About", url: "about" }
]

export default function Header({activePage}) {
  const [navbarOpen, setNavbarOpen] = React.useState(false)

  const entries = links.map((link, index) => {
    //check to see if the url is an external link
    var external = link.url.substring(0, 4) === "http"
    return (
      <span 
        className={`text-xl font-bold whitespace-nowrap w-full hover:text-primary hover:underline ${activePage && activePage===link.text? 'text-primary underline':''}`} 
        key={index}>
        {external ? (
          //if it's external, render a with href to new tab
          <a
            href={link.url}
            target="_blank"
            rel="noopener noreferrer"
            className="block md:inline"
          >
            {link.text}
          </a>
        ) : (
          //if it's internal, use Gatsby's built-in internal link component
          <Link to={`/${link.url}`} key={index} className="block md:inline">
            {link.text}
          </Link>
        )}
      </span>
    )
  })

  return (
    <nav className={`flex mb-4 z-10 fixed w-full top-0 bg-white shadow-lg`}>
      <div className="relative flex flex-wrap justify-between container">
        <div className="h-20 flex items-center w-full">
          <div className="flex items-center lg:order-none w-full text-gray-900">
            <div className="w-1/5 flex items-center lg:hidden">
              <button type="button" onClick={() => setNavbarOpen(!navbarOpen)}>
                <img src={Menu} alt="Hamburger menu icon" className="h-12" />
              </button>
            </div>
            <div className="w-3/5">
              <Link to={"/"}>
                <img
                  src={Wordmark}
                  alt="Digital Sabbath logo"
                  className="my-auto h-10"
                />
              </Link>
            </div>
          </div>
          <nav className="lg:w-2/5 hidden lg:block">
            <div className="flex flex-col items-end">
              <div className="space-x-6">{entries}</div>
            </div>
          </nav>
        </div>
        <div
          className={
            "lg:hidden flex-grow items-center ml-auto" +
            (navbarOpen ? " flex" : " hidden")
          }
        >
          <ul className="flex flex-col py-4 font-bold space-x-0 space-y-4 w-full">
            {entries}
          </ul>
        </div>
      </div>
    </nav>
  )
}
